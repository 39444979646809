document.addEventListener("turbolinks:load", function(event) {
  let cropboxWrappers = document.querySelectorAll('#cropbox');
  if (cropboxWrappers.length > 0) {
    var Croppr = require('croppr');
      var croppr = new Croppr('#cropbox', {
      // var croppr = new Croppr('.pic-preview', {
        aspectRatio: 1,
        returnMode: 'real',
        onCropEnd: function(value) {
          // console.log(value)
          // console.log(value.x, value.y, value.width, value.height);
          if (!isNaN(value.x)) {
            $('.page_form.is-active .book_page_crop_x').val(value.x)
            $('.page_form.is-active .book_page_crop_y').val(value.y)
            $('.page_form.is-active .book_page_crop_w').val(value.width)
            $('.page_form.is-active .book_page_crop_h').val(value.height)
          }
        },
        onInitialize: function(instance) {
          // do things here
          // alert('copper ready')
        }
        // options
      });
      $('.open-modal.cropper').on('click', function (e) {
        var_w = Number($(this).data('w'))
        var_h = Number($(this).data('h'))
        var_x = Number($(this).data('x'))
        var_y = Number($(this).data('y'))
        croppr.setImage($(this).data('src'))
        croppr.resizeTo(var_w, var_h, [0, 0])
        croppr.moveTo(var_x, var_y)
        // console.log(var_w,  var_h, var_x, var_y)
      })
  };
});
