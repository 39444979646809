// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

let Trix = window.Trix = require("trix")
require("@rails/actiontext")
require("packs/richtext")
import $ from 'jquery';
require("select2");

require("packs/global");


document.addEventListener("turbolinks:load", function(event) {
  return $("#resource_grade_bands").select2({
    tags: true,
    allowClear: true,
    createTag: function(params) {
      var term;
      term = $.trim(params.term);
      if (term === '') {
        return null;
      }
      return {
        id: term,
        text: term,
        newTag: true
      };
    }
  });
});
